import { useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { Search as SearchIcon } from 'react-bootstrap-icons';
import { useQuery } from './helpers/Url'

import Container from './Container'
import Controller from 'components/Controller';

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useLanguageContext } from 'providers/Language'
import { useCurrencyContext } from 'providers/Currency'
import { useAppContext } from 'providers/App'
import PermissionsProvider from 'providers/Permissions';

// pages
import PageLoader from 'components/misc/PageLoader';
import Dashboard from 'components/pages/dashboard/Index';
import Search from 'components/pages/search/Index';
import Categories from 'components/pages/categories/Index';
import AmountTypes from 'components/pages/amount_types/Index';
import Articles from 'components/pages/articles/Index';
import ArticlesMassUpdate from 'components/pages/articles/MassUpdate';
import ArticlesPrintList from 'components/pages/articles/PrintList';
import ArticlesPrintListFree from 'components/pages/articles/PrintListFree';
import Stores from 'components/pages/stores/Index';
import Suppliers from 'components/pages/suppliers/Index';
import Features from 'components/pages/features/Index';
import Languages from 'components/pages/languages/Index';
import DepotsGroups from 'components/pages/depots_groups/Index';
import Depots from 'components/pages/depots/Index';
import Login from 'components/pages/auth/Login';
import Logout from 'components/pages/auth/Logout';
import SetPassword from 'components/pages/auth/SetPassword';
import Brands from 'components/pages/brands/Index';
import Currencies from 'components/pages/currencies/Index';
import StoreLoads from 'components/pages/storeloads/Index';
import StoreLoad from 'components/pages/storeloads/StoreLoad';
import StoreMoves from 'components/pages/storemoves/Index';
import StoreMove from 'components/pages/storemoves/StoreMove';
import Revisions from 'components/pages/revisions/Index';
import Revision from 'components/pages/revisions/Revision';
import ClientSource from 'components/pages/clients_sources/Index';
import ClientGroup from 'components/pages/clients_groups/Index';
import Client from 'components/pages/clients/Index';
import Role from 'components/pages/roles/Index';
import RolePermission from 'components/pages/roles_permissions/Index';
import StorePermission from 'components/pages/stores_permissions/Index';
import Admin from 'components/pages/admins/Index';
import GroupArticle from 'components/pages/article_groups/Index';
import CardType from 'components/pages/card_types/Index';
import PackageCard from 'components/pages/package_card/Index';
import Salon from 'components/pages/salons/Index';
import Review from 'components/pages/article_reviews/Index';
import News from 'components/pages/news/Index';
import Reviews from 'components/pages/reviews/Index';
import CategoriesOptions from 'components/pages/categories_options/Index';
import BrandsOptions from 'components/pages/brands_options/Index';
import StoresOptions from 'components/pages/stores_options/Index';
import Navigation from 'components/pages/navigation/Index';
import Page from 'components/pages/pages/Index';
import Config from 'components/pages/config/Index';
import SeoContents from 'components/pages/seo_contents/Index';
import PaymentMethod from 'components/pages/payment_methods/Index.js';
import Device from 'components/pages/devices/Index';
import Country from 'components/pages/countries/Index';
import Company from 'components/pages/companies/Index';
import Speditor from 'components/pages/speditors/Index';
import CashDesks from 'components/pages/cashdesks/Index';
import PriceRuleGroup from 'components/pages/price_rules_groups/Index';
import PriceRule from 'components/pages/price_rules/Index';
import ReasonsRevision from 'components/pages/reasons_revisions/Index';
import EmailTemplate from 'components/pages/email_templates/Index';
import OrdersStatuses from 'components/pages/orders_statuses/Index';
import ReturnsStatuses from 'components/pages/returns_statuses/Index';
import Labels from 'components/pages/labels/Index';

// orders
import Orders from 'components/pages/orders/Index';
import Order from 'components/pages/orders/Order';

// accounts
import Accounts from 'components/pages/accounts/Index';
import Account from 'components/pages/accounts/Account';

// payments
import Payments from 'components/pages/payments/Index';

// returns
import Returns from 'components/pages/returns/Index';
import ReturnOrder from 'components/pages/returns/ReturnOrder';

// invoices
import Invoices from 'components/pages/invoices/Index';
import Invoice from 'components/pages/invoices/Invoice';

// documents
import Protocols from 'components/pages/protocols/Index';
import Protocol from 'components/pages/protocols/Document';
import Warranties from 'components/pages/warranty/Index';
import Warranty from 'components/pages/warranty/Document';
import Contracts from 'components/pages/contracts/Index';
import Contract from 'components/pages/contracts/Document';

// intrastats
import Intrastats from 'components/pages/intrastats/Index';
import Intrastat from 'components/pages/intrastats/Intrastat';

// reports
// import ReportArticlesActualPrices from 'components/pages/reports/articles/actual_prices/Index';
// import ReportArticlesDeliveryPrices from 'components/pages/reports/articles/delivery_prices/Index';
// import ReportStorageTotalAmounts from 'components/pages/reports/storage/total_amounts/Index';
// import ReportStorageAmountsByDepots from 'components/pages/reports/storage/amounts_by_depots/Index';
// import ReportStorageAmountsByStores from 'components/pages/reports/storage/amounts_by_stores/Index';
// import ReportStorageAmountsByTotals from 'components/pages/reports/storage/amounts_by_totals/Index';
// import ReportStorageAmountsByDate from 'components/pages/reports/storage/amounts_by_date/Index';
// import ReportStorageAmountsByDateAndStores from 'components/pages/reports/storage/amounts_by_date_stores/Index';
// import ReportStorageOutgoes from 'components/pages/reports/storage/outgoes/Index';
// import ReportStorageOutgoesSum from 'components/pages/reports/storage/outgoes_sum/Index';
// import ReportStorageListDetails from 'components/pages/reports/storage/list_details/Index';
// import ReportStorageListResume from 'components/pages/reports/storage/list_resume/Index';

// import ReportMonitoringTotals from 'components/pages/reports/monitoring/totals/Index';
// import ReportMonitoringTotalsByCashdesks from 'components/pages/reports/monitoring/totals_by_cashdesks/Index';
// import ReportMonitoringTotalsByStores from 'components/pages/reports/monitoring/totals_by_stores/Index';
// import ReportMonitoringTotalsByPaymethods from 'components/pages/reports/monitoring/totals_by_paymethods/Index';
// import ReportMonitoringTotalsByUsers from 'components/pages/reports/monitoring/totals_by_users/Index';
// import ReportMonitoringTotalsByUsersAndPaymethods from 'components/pages/reports/monitoring/totals_by_users_paymethods/Index';
// import ReportMonitoringTotalsByClientGroups from 'components/pages/reports/monitoring/totals_by_client_groups/Index';
// import ReportMonitoringTotalsByClients from 'components/pages/reports/monitoring/totals_by_clients/Index';
// import ReportMonitoringTotalsByArticles from 'components/pages/reports/monitoring/totals_by_articles/Index';
// import ReportMonitoringLoad from 'components/pages/reports/monitoring/loads/Index';
// import ReportMonitoringPeriodStatus from 'components/pages/reports/monitoring/period_status/Index';
// import ReportSalesPeriodsTotals from 'components/pages/reports/sales/totals/Index';
// import ReportSalesArticlesTotals from 'components/pages/reports/sales/articles/totals/Index';
// import ReportSalesArticlesWeekdays from 'components/pages/reports/sales/articles/weekdays/Index';
// import ReportSalesArticlesDays from 'components/pages/reports/sales/articles/days/Index';
// import ReportSalesArticlesHours from 'components/pages/reports/sales/articles/hours/Index';
// import ReportSalesArticlesMonths from 'components/pages/reports/sales/articles/months/Index';
// import ReportSalesArticlesStores from 'components/pages/reports/sales/articles/stores/Index';
// import ReportSalesArticlesSellout from 'components/pages/reports/sales/articles/sellout/Index';
// import ReportSalesCategoriesTotals from 'components/pages/reports/sales/categories/totals/Index';
// import ReportSalesCategoriesWeekdays from 'components/pages/reports/sales/categories/weekdays/Index';
// import ReportSalesCategoriesHours from 'components/pages/reports/sales/categories/hours/Index';
// import ReportSalesStoresTotals from 'components/pages/reports/sales/stores/totals/Index';
// import ReportSalesStoresPeriods from 'components/pages/reports/sales/stores/periods/Index';
// import ReportSalesUsersTotals from 'components/pages/reports/sales/users/totals/Index';
// import ReportSalesUsersPaymethods from 'components/pages/reports/sales/users/paymethods/Index';
// import ReportSalesClientsTotals from 'components/pages/reports/sales/clients/totals/Index';
// import ReportSalesClientGroups from 'components/pages/reports/sales/clients/groups/Index';
// import ReportSalesClientsMonths from 'components/pages/reports/sales/clients/months/Index';
// import ReportSalesAccountsRowsDetails from 'components/pages/reports/sales/accountsrows/details/Index';
// import ReportStoreloadsTotals from 'components/pages/reports/storeloads/totals/Index';
// import ReportStoreloadsArticles from 'components/pages/reports/storeloads/articles/Index';
// import ReportStoreloadsOperations from 'components/pages/reports/storeloads/operations/Index';
// import ReportStoreloadsSuppliers from 'components/pages/reports/storeloads/suppliers/Index';

// errors
import NotFound from 'components/pages/errors/NotFound';
import Unauthorized from 'components/pages/errors/Unauthorized';

// middleware
import Authed from 'middlewares/Authed';
import Guest from 'middlewares/Guest';

// misc
import Alert from 'components/misc/Alert';

// style
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/app.scss';
import 'assets/scss/tables.scss';

import SharedModals from 'components/modals/Shared';
import FlashMessages from 'components/layout/FlashMessages';
import Refs from 'Refs';
import SuperAdmin from 'middlewares/SuperAdmin';
import { Toast } from 'react-bootstrap';

function App() {

    const app = useAppContext()
    const auth = useAuthDataContext()
    const langs = useLanguageContext()
    const currency = useCurrencyContext()
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    const container = Container.getInstance()

    const [booted, setBooted] = useState(false)

    const [bootedServices, setBootedServices] = useState({
        user: false,
        langs: false,
        currency: false,
    })

    container.setAuth(auth)
    container.setNavigate(navigate)
    container.setLocation(location)
    container.setQuery(query)

    useEffect(() => {
        if (auth.isLogged()) {
            auth.loadData()
                .then(() => {
                    makeBooted('user')
                })
        } else {
            makeBooted('user')
        }

        langs.loadData()
            .then(() => {
                makeBooted('langs')
            })

        currency.loadData()
            .then(() => {
                makeBooted('currency')
            })
    }, [])

    useEffect(() => {
        const all = Object.values(bootedServices).length
        const booted = Object.values(bootedServices).filter(booted => booted === true).length

        setBooted(all === booted)
    }, [bootedServices])

    // при промяна на url да промени с активния ТО
    useEffect(() => {
        const activeStoreId = auth.getUser()?.getStoreId();

        if (activeStoreId) {
            if (query.has('sid')) {
                let newStoreId = query.get('sid');

                if (Number(newStoreId) !== Number(activeStoreId)) {
                    auth.setActiveStore(newStoreId);
                }
            } else {
                if (location.pathname === '/unauthorized') {
                    return;
                }

                query.set('sid', activeStoreId);

                navigate('?' + query.toString(), {
                    replace: true
                });
            }
        }
    }, [location])

    const makeBooted = (service, booted = true) => {
        setBootedServices(prev => ({
            ...prev,
            [service]: booted
        }))
    }

    useLayoutEffect(() => {
        const onClick = e => {
            if (e.target.closest('tr')) {
                e.target.closest('table').querySelectorAll('tr').forEach(el => {
                    el.classList.remove('clicked');
                });

                e.target.closest('tr').classList.add('clicked');
            }
        }

        document.querySelector('body').addEventListener('click', onClick);

        return () => {
            document.querySelector('body').removeEventListener('click', onClick);
        }
    }, []);

    if (!booted) {
        return <PageLoader show />
    }

    return (
        <>
            <Routes>
                <Route path="/" exact element={<Controller middleware={[Authed]}><Dashboard /></Controller>} />
                <Route path="/search" exact element={<Controller middleware={[Authed]}><Search /></Controller>} />
                <Route path="/categories" exact element={<Controller middleware={[Authed]} permission="categories_index"><Categories /></Controller>} />
                <Route path="/units" exact element={<Controller middleware={[Authed]} permission="amounttypes_edit"><AmountTypes /></Controller>} />
                <Route path="/articles" exact element={<Controller middleware={[Authed]} permission="articles_index"><Articles /></Controller>} />
                <Route path="/articles-mass-update" exact element={<Controller middleware={[Authed]} permission="articles_edit"><ArticlesMassUpdate /></Controller>} />
                <Route path="/articles-print-list" exact element={<Controller middleware={[Authed]} permission="articles_index"><ArticlesPrintList /></Controller>} />
                <Route path="/articles-print-list-free" exact element={<Controller middleware={[Authed]} permission="articles_index"><ArticlesPrintListFree /></Controller>} />
                <Route path="/stores" exact element={<Controller middleware={[Authed]} permission="barsys_index"><Stores /></Controller>} />
                <Route path="/cashdesks" exact element={<Controller middleware={[Authed]} permission="poses_index"><CashDesks /></Controller>} />
                <Route path="/suppliers" exact element={<Controller middleware={[Authed]} permission="suppliers_index"><Suppliers /></Controller>} />
                <Route path="/features" exact element={<Controller middleware={[Authed]} permission="articles_details"><Features /></Controller>} />
                <Route path="/languages" exact element={<Controller middleware={[Authed]} permission="languages_index"><Languages /></Controller>} />
                <Route path="/depots/groups" exact element={<Controller middleware={[Authed]}><DepotsGroups /></Controller>} />
                <Route path="/depots" exact element={<Controller middleware={[Authed]} permission="depots_index"><Depots /></Controller>} />
                <Route path="/brands" exact element={<Controller middleware={[Authed]}><Brands /></Controller>} />
                <Route path="/currencies" exact element={<Controller middleware={[Authed]} permission="currencies_index"><Currencies /></Controller>} />
                <Route path="/labels" exact element={<Controller middleware={[Authed]} permission="misc_get_lable_templates"><Labels /></Controller>} />

                <Route path="/storage/loads" exact element={<Controller middleware={[Authed]} permission="storeloads_opened, storeloads_closed"><StoreLoads /></Controller>} />
                <Route path="/storage/loads/new" exact element={<Controller middleware={[Authed]} permission="storeloads_edit"><StoreLoad /></Controller>} />
                <Route path="/storage/loads/:id/edit" exact element={<Controller middleware={[Authed]} permission="storeloads_edit"><StoreLoad /></Controller>} />
                <Route path="/storage/moves" exact element={<Controller middleware={[Authed]} permission="storemoves_opened, storemoves_closed"><StoreMoves /></Controller>} />
                <Route path="/storage/moves/new" exact element={<Controller middleware={[Authed]} permission="storemoves_edit"><StoreMove /></Controller>} />
                <Route path="/storage/moves/:id/edit" exact element={<Controller middleware={[Authed]} permission="storemoves_edit"><StoreMove /></Controller>} />
                <Route path="/storage/revisions" exact element={<Controller middleware={[Authed]} permission="revisions_opened, revisions_closed"><Revisions /></Controller>} />
                <Route path="/storage/revisions/:id/edit" exact element={<Controller middleware={[Authed]} permission="revisions_edit"><Revision /></Controller>} />

                <Route path="/orders" exact element={<Controller middleware={[Authed]} permission="clientorders_index"><Orders /></Controller>} />
                <Route path="/orders/new" exact element={<Controller middleware={[Authed]} permission="clientorders_edit"><Order /></Controller>} />
                <Route path="/orders/:id/edit" exact element={<Controller middleware={[Authed]} permission="clientorders_edit"><Order /></Controller>} />
                <Route path="/orders-statuses" exact element={<Controller middleware={[Authed]} permission="clientorders_statuses"><OrdersStatuses /></Controller>} />

                <Route path="/accounts" exact element={<Controller middleware={[Authed]} permission="accounts_opened, accounts_closed"><Accounts /></Controller>} />
                <Route path="/accounts/new" exact element={<Controller middleware={[Authed]} permission="accounts_edit"><Account /></Controller>} />
                <Route path="/accounts/:id/edit" exact element={<Controller middleware={[Authed]} permission="accounts_edit"><Account /></Controller>} />

                <Route path="/payments" exact element={<Controller middleware={[Authed]} permission="payments_index"><Payments /></Controller>} />

                <Route path="/returns" exact element={<Controller middleware={[Authed]} permission="clientorders_index"><Returns /></Controller>} />
                <Route path="/returns/new" exact element={<Controller middleware={[Authed]}><ReturnOrder /></Controller>} />
                <Route path="/returns/:id/edit" exact element={<Controller middleware={[Authed]}><ReturnOrder /></Controller>} />
                <Route path="/returns-statuses" exact element={<Controller middleware={[Authed]}><ReturnsStatuses /></Controller>} />

                <Route path="/invoices" exact element={<Controller middleware={[Authed]} permission="invoices_index"><Invoices /></Controller>} />
                <Route path="/invoices/new" exact element={<Controller middleware={[Authed]} permission="invoices_create"><Invoice /></Controller>} />
                <Route path="/invoices/:id/edit" exact element={<Controller middleware={[Authed]} permission="invoices_edit"><Invoice /></Controller>} />

                <Route path="/protocols" exact element={<Controller middleware={[Authed]} permission="document_acceptance_protocol_view"><Protocols /></Controller>} />
                <Route path="/protocols/new" exact element={<Controller middleware={[Authed]} permission="document_acceptance_protocol_view"><Protocol /></Controller>} />
                <Route path="/protocols/:id/edit" exact element={<Controller middleware={[Authed]} permission="document_acceptance_protocol_view"><Protocol /></Controller>} />

                <Route path="/warranty" exact element={<Controller middleware={[Authed]} permission="document_warranty_view"><Warranties /></Controller>} />
                <Route path="/warranty/new" exact element={<Controller middleware={[Authed]} permission="document_warranty_view"><Warranty /></Controller>} />
                <Route path="/warranty/:id/edit" exact element={<Controller middleware={[Authed]} permission="document_warranty_view"><Warranty /></Controller>} />

                <Route path="/contracts" exact element={<Controller middleware={[Authed]} permission="document_contract_view"><Contracts /></Controller>} />
                <Route path="/contracts/new" exact element={<Controller middleware={[Authed]} permission="document_contract_view"><Contract /></Controller>} />
                <Route path="/contracts/:id/edit" exact element={<Controller middleware={[Authed]} permission="document_contract_view"><Contract /></Controller>} />

                <Route path="/intrastats" exact element={<Controller middleware={[Authed]} permission="intrastat"><Intrastats /></Controller>} />
                <Route path="/intrastats/:id/edit" exact element={<Controller middleware={[Authed]} permission="intrastat"><Intrastat /></Controller>} />

                {/* <Route path="/reports/articles/actual-prices" exact element={<Controller middleware={[Authed]} permission="reports_articles_actual_prices, report_full_access"><ReportArticlesActualPrices /></Controller>} />
                <Route path="/reports/articles/delivery-prices" exact element={<Controller middleware={[Authed]} permission="reports_articles_delivery_prices, report_full_access"><ReportArticlesDeliveryPrices /></Controller>} />
                <Route path="/reports/storage/total-amounts" exact element={<Controller middleware={[Authed]} permission="store_amounts, report_full_access"><ReportStorageTotalAmounts /></Controller>} />
                <Route path="/reports/storage/amounts-by-depots" exact element={<Controller middleware={[Authed]} permission="store_amounts_by_stores, report_full_access"><ReportStorageAmountsByDepots /></Controller>} />
                <Route path="/reports/storage/amounts-by-stores" exact element={<Controller middleware={[Authed]} permission="store_amounts_by_barsys, report_full_access"><ReportStorageAmountsByStores /></Controller>} />
                <Route path="/reports/storage/amounts-by-totals" exact element={<Controller middleware={[Authed]} permission="store_amounts_by_stores, report_full_access"><ReportStorageAmountsByTotals /></Controller>} />
                <Route path="/reports/storage/amounts-by-date" exact element={<Controller middleware={[Authed]} permission="reports_store_amounts_by_date, report_full_access"><ReportStorageAmountsByDate /></Controller>} />
                <Route path="/reports/storage/amounts-by-date-stores" exact element={<Controller middleware={[Authed]} permission="store_amounts_by_barsys, report_full_access"><ReportStorageAmountsByDateAndStores /></Controller>} />
                <Route path="/reports/storage/outgoes" exact element={<Controller middleware={[Authed]} permission="reports_store_outgoes, report_full_access"><ReportStorageOutgoes /></Controller>} />
                <Route path="/reports/storage/outgoes-sum" exact element={<Controller middleware={[Authed]} permission="reports_store_outgoes, report_full_access"><ReportStorageOutgoesSum /></Controller>} />
                <Route path="/reports/storage/list-details" exact element={<Controller middleware={[Authed]} permission="reports_store_list_details, report_full_access"><ReportStorageListDetails /></Controller>} />
                <Route path="/reports/storage/list-resume" exact element={<Controller middleware={[Authed]} permission="reports_store_list_details, report_full_access"><ReportStorageListResume /></Controller>} />
                <Route path="/reports/monitoring/totals" exact element={<Controller middleware={[Authed]} permission="mon_totals, report_full_access"><ReportMonitoringTotals /></Controller>} />
                <Route path="/reports/monitoring/totals-by-cashdesks" exact element={<Controller middleware={[Authed]} permission="mon_poses, report_full_access"><ReportMonitoringTotalsByCashdesks /></Controller>} />
                <Route path="/reports/monitoring/totals-by-paymethods" exact element={<Controller middleware={[Authed]} permission="mon_paymethods, report_full_access"><ReportMonitoringTotalsByPaymethods /></Controller>} />
                <Route path="/reports/monitoring/totals-by-users" exact element={<Controller middleware={[Authed]} permission="mon_users, report_full_access"><ReportMonitoringTotalsByUsers /></Controller>} />
                <Route path="/reports/monitoring/totals-by-users-paymethods" exact element={<Controller middleware={[Authed]} permission="mon_users, report_full_access"><ReportMonitoringTotalsByUsersAndPaymethods /></Controller>} />
                <Route path="/reports/monitoring/totals-by-client-groups" exact element={<Controller middleware={[Authed]} permission="mon_clientgroups, report_full_access"><ReportMonitoringTotalsByClientGroups /></Controller>} />
                <Route path="/reports/monitoring/totals-by-clients" exact element={<Controller middleware={[Authed]} permission="mon_clients, report_full_access"><ReportMonitoringTotalsByClients /></Controller>} />
                <Route path="/reports/monitoring/totals-by-articles" exact element={<Controller middleware={[Authed]} permission="mon_articles, report_full_access"><ReportMonitoringTotalsByArticles /></Controller>} />
                <Route path="/reports/monitoring/load" exact element={<Controller middleware={[Authed]} permission="mon_period_graphs, report_full_access"><ReportMonitoringLoad /></Controller>} />
                <Route path="/reports/monitoring/totals-by-stores" exact element={<Controller middleware={[Authed]} permission="mon_summary_by_barsys, report_full_access"><ReportMonitoringTotalsByStores /></Controller>} />
                <Route path="/reports/monitoring/period-status" exact element={<Controller middleware={[Authed]} permission="mon_period_status, report_full_access"><ReportMonitoringPeriodStatus /></Controller>} />
                <Route path="/reports/sales/periods/totals" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_periods, reports_periods, report_full_access"><ReportSalesPeriodsTotals /></Controller>} />
                <Route path="/reports/sales/articles/totals" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_articles, report_full_access"><ReportSalesArticlesTotals /></Controller>} />
                <Route path="/reports/sales/articles/weekdays" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_articles_and_weekdays, report_full_access"><ReportSalesArticlesWeekdays /></Controller>} />
                <Route path="/reports/sales/articles/days" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_articles_and_weekdays, report_full_access"><ReportSalesArticlesDays /></Controller>} />
                <Route path="/reports/sales/articles/hours" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_hours, report_full_access"><ReportSalesArticlesHours /></Controller>} />
                <Route path="/reports/sales/articles/months" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_articles_and_months, report_full_access"><ReportSalesArticlesMonths /></Controller>} />
                <Route path="/reports/sales/articles/stores" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_articles_and_barsys, report_full_access"><ReportSalesArticlesStores /></Controller>} />
                <Route path="/reports/sales/articles/sellout" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_articles, report_full_access"><ReportSalesArticlesSellout /></Controller>} />
                <Route path="/reports/sales/categories/totals" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_cats, report_full_access"><ReportSalesCategoriesTotals /></Controller>} />
                <Route path="/reports/sales/categories/weekdays" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_cats_and_weekdays, report_full_access"><ReportSalesCategoriesWeekdays /></Controller>} />
                <Route path="/reports/sales/categories/hours" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_cats_and_hours, report_full_access"><ReportSalesCategoriesHours /></Controller>} />
                <Route path="/reports/sales/stores/totals" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_barsys, report_full_access"><ReportSalesStoresTotals /></Controller>} />
                <Route path="/reports/sales/stores/periods" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_barsys_periods, report_full_access"><ReportSalesStoresPeriods /></Controller>} />
                <Route path="/reports/sales/users/totals" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_users_totals, report_full_access"><ReportSalesUsersTotals /></Controller>} />
                <Route path="/reports/sales/users/paymethods" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_users_paymethods, report_full_access"><ReportSalesUsersPaymethods /></Controller>} />
                <Route path="/reports/sales/clients/totals" exact element={<Controller middleware={[Authed]} permission="reports_clients_totals, report_full_access"><ReportSalesClientsTotals /></Controller>} />
                <Route path="/reports/sales/clients/groups" exact element={<Controller middleware={[Authed]} permission="reports_clients_groups_totals, report_full_access"><ReportSalesClientGroups /></Controller>} />
                <Route path="/reports/sales/clients/months" exact element={<Controller middleware={[Authed]} permission="reports_clients_by_months, report_full_access"><ReportSalesClientsMonths /></Controller>} />
                <Route path="/reports/sales/accountsrows/details" exact element={<Controller middleware={[Authed]} permission="reports_sales_by_orders, report_full_access"><ReportSalesAccountsRowsDetails /></Controller>} />
                <Route path="/reports/storeloads/totals" exact element={<Controller middleware={[Authed]} permission="report_storeloads_totals, report_full_access"><ReportStoreloadsTotals /></Controller>} />
                <Route path="/reports/storeloads/articles" exact element={<Controller middleware={[Authed]} permission="report_storeloads_totals, report_full_access"><ReportStoreloadsArticles /></Controller>} />
                <Route path="/reports/storeloads/operations" exact element={<Controller middleware={[Authed]} permission="report_storeloads_totals, report_full_access"><ReportStoreloadsOperations /></Controller>} />
                <Route path="/reports/storeloads/suppliers" exact element={<Controller middleware={[Authed]} permission="report_store_loads_by_supplier, report_full_access"><ReportStoreloadsSuppliers /></Controller>} /> */}

                <Route path="/clients" exact element={<Controller middleware={[Authed]} permission="clients_index"><Client /></Controller>} />
                <Route path="/clients/sources" exact element={<Controller middleware={[Authed]}><ClientSource /></Controller>} />
                <Route path="/clients/groups" exact element={<Controller middleware={[Authed]} permission="clients_groupsindex"><ClientGroup /></Controller>} />

                <Route path="/roles" exact element={<Controller middleware={[Authed]} permission="roles_index"><Role /></Controller>} />
                <Route path="/roles/permissions" exact element={<Controller middleware={[Authed]} permission="rights_index"><PermissionsProvider><RolePermission /></PermissionsProvider></Controller>} />
                <Route path="/stores/permissions" exact element={<Controller middleware={[Authed]} permission="rights_by_barsys"><StorePermission /></Controller>} />

                <Route path="/groups/articles" exact element={<Controller middleware={[Authed]} permission="cards_articlegroups"><GroupArticle /></Controller>} />
                <Route path="/cards/types" exact element={<Controller middleware={[Authed]} permission="cards_index"><CardType /></Controller>} />
                <Route path="/cards" exact element={<Controller middleware={[Authed]} permission="cards_instances"><PackageCard /></Controller>} />

                <Route path="/pages" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><Page /></Controller>} />
                <Route path="/stores_options" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><StoresOptions /></Controller>} />
                <Route path="/brands_options" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><BrandsOptions /></Controller>} />
                <Route path="/categories_options" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><CategoriesOptions /></Controller>} />
                <Route path="/navigation" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><Navigation /></Controller>} />
                <Route path="/config" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><Config /></Controller>} />
                <Route path="/seo" exact element={<Controller middleware={[Authed]} permission="eshop_manage"><SeoContents /></Controller>} />
                <Route path="/reviews" exact element={<Controller middleware={[Authed]}><Reviews /></Controller>} />
                <Route path="/news" exact element={<Controller middleware={[Authed]}><News /></Controller>} />

                <Route path="/salons" exact element={<Controller middleware={[Authed]}><Salon /></Controller>} />
                <Route path="/countries" exact element={<Controller middleware={[Authed]}><Country /></Controller>} />
                <Route path="/reasons/revisions" exact element={<Controller middleware={[Authed]}><ReasonsRevision /></Controller>} />
                <Route path="/email_templates" exact element={<Controller middleware={[Authed, SuperAdmin]}><EmailTemplate /></Controller>} />
                <Route path="/payment-methods" exact element={<Controller middleware={[Authed]} permission="paymentmethods_index"><PaymentMethod /></Controller>} />
                <Route path="/companies" exact element={<Controller middleware={[Authed]} permission="companies_index"><Company /></Controller>} />
                <Route path="/speditors" exact element={<Controller middleware={[Authed]} permission="speditors_index"><Speditor /></Controller>} />
                <Route path="/devices" exact element={<Controller middleware={[Authed]} permission="printers_index"><Device /></Controller>} />
                <Route path="/price-rules/groups" exact element={<Controller middleware={[Authed]} permission="pricelists_index"><PriceRuleGroup /></Controller>} />
                <Route path="/price-rules" exact element={<Controller middleware={[Authed]} permission="pricelists_index"><PriceRule /></Controller>} />

                <Route path="/admins" exact element={<Controller middleware={[Authed]} permission="users_index"><Admin /></Controller>} />

                <Route path="/login" exact element={<Login />} />
                <Route path="/logout" exact element={<Controller middleware={[Authed]}><Logout /></Controller>} />
                <Route path="/set-password" exact element={<Controller middleware={[Guest]}><SetPassword /></Controller>} />

                <Route path="/unauthorized" element={<Unauthorized />} />

                <Route path="*" element={<NotFound />} />
            </Routes>

            {auth.isLogged() &&
                <SharedModals />
            }

            <FlashMessages />
        </>
    );
}

export default App;
