import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Dropdown, InputGroup, Card, Table, Placeholder } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import { sortable } from 'react-sortable';
import { cloneDeep } from 'lodash';

//helpers
import Api from 'helpers/Api'
import { addExtraPrice, calcExtraPrice, removeExtraPrice } from 'helpers/Price';


//providers
import { useLanguageContext } from 'providers/Language'
import { useArticleContext } from '../Provider';
import { useAppContext } from 'providers/App';
import { useCurrencyContext } from 'providers/Currency';
import { useAuthDataContext } from 'providers/Auth';

import Info from 'components/modals/Info';

//misc
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import Loader from 'components/misc/Loader';
import Alert from 'components/misc/Alert';

import './style.scss';

import { CKEditor } from '@ckeditor/ckeditor5-react';

const ClassicEditor = window.ClassicEditor;

function SortableItem(props) {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}

const SortItem = sortable(SortableItem);

function Index(props, ref) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const langs = useLanguageContext();
    const currencies = useCurrencyContext();
    const context = useArticleContext();

    const { state, setState } = context;
    const { validations, setValidations } = context;
    const { defaultData } = context;

    const [stores, setStores] = useState([]);
    const [depots, setDepots] = useState([]);

    const [amountTypes, setAmountTypes] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);

    const { show, hide, edit, refresh } = props;

    const category = props.category || null

    useImperativeHandle(ref, () => ({
        save: (e) => {
            handleSave(e);
        },
    }));

    const formRef = useRef(null);

    const inputFileRef = useRef(null);
    const validationModalRef = useRef(null);

    useEffect(() => {
        loadData();
    }, [props.id]);

    // useEffect(() => {
    //     if (state.data.details.length === 0) {
    //         setState(prev => ({
    //             ...prev,
    //             data: {
    //                 ...prev.data,
    //                 details: [{}]
    //             }
    //         }))
    //     }
    // }, [state.data.details]);

    useEffect(() => {

        if (amountTypes.length === 0) {
            Api.get('articles/amount-types')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setAmountTypes(res.data);
                    }
                });
        }

        if (stores.length === 0) {
            Api.get('articles/stores')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setStores(res.data);
                    }
                });
        }

        if (depots.length === 0) {
            Api.get('articles/depots')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setDepots(res.data);
                    }
                });
        }

        if (taxGroups.length === 0) {
            Api.get('articles/tax-groups')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setTaxGroups(res.data);
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        let tax = getTaxGroupById(state.data.tax_id);

        let taxval = parseFloat(tax?.value || 0);

        let avgDeliveryPriceWithVat = addExtraPrice(state.data.avg_delivery_price, taxval);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
            }
        }));

    }, [state.data.tax_id, state.data.avg_delivery_price]);

    useEffect(() => {
        setProfit();
        setMargin();
    }, [state.data.avg_delivery_price, state.data.amount_unit])


    const loadData = () => {
        if (!props.id) {
            return;
        }

        if (state.data.id === props.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            dataLoading: state.data.id ? false : true,
        }));

        Api.get('articles/show?id=' + props.id)
            .then(res => {

                let data = res.data;

                if (data.storesdetails) {
                    let storesdetails = {}

                    res.data.storesdetails.map(detail => {
                        storesdetails[detail.store_id] = detail;
                    });

                    data.storesdetails = storesdetails;
                } else {
                    data.storesdetails = defaultData.storesdetails;
                }

                if (data.depotsdetails) {
                    let depotsdetails = {}

                    res.data.depotsdetails.map(detail => {
                        depotsdetails[detail.depot_id] = detail;
                    });

                    data.depotsdetails = depotsdetails;
                } else {
                    data.depotsdetails = defaultData.depotsdetails;
                }


                setState(prev => ({
                    ...prev,
                    data: data,
                    initialData: data,
                    dataLoading: false,
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.data.id ? 'articles/edit' : 'articles/add';

        let data = new FormData(formRef.current);

        if (state.data.id) {
            data.append('id', state.data.id)
        }

        if (category) {
            data.append('category_id', category.id)
        }

        if (!state.data.is_for_sale) {
            data.append('is_for_sale', 0);
        }

        state.data.files.map((file, i) => {
            if (file.was_recently_attached) {
                data.append('files[' + i + ']', file);
            }
        });

        if (typeof props.params === 'object') {
            Object.entries(props.params).map(e => {
                data.append(e[0], e[1]);
            });
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }

            app.showSuccess();

            // refresh(res.data.id);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)

                // скриването unmount-ва модала и създава проблеми...

                // hide(() => {
                //     let modal = validationModalRef.current;

                //     if (modal) {
                //         modal.open();

                //         modal.onClose(() => {

                //             show();
                //         });
                //     }
                // });
            } else {
                app.showError();
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTaxChange = e => {
        let id = e.target.value;

        let tax = getTaxGroupById(id);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                tax_id: id,
            }
        }));
    }

    const getTaxGroupById = id => {
        return taxGroups.find(c => Number(c.id) === Number(id));
    }

    const setProfit = (avgDeliveryPrice = null, amountUnit = null, price = null) => {

        let tax = getTaxGroupById(state.data.tax_id);

        let taxval = parseFloat(tax?.value || 0);

        if (avgDeliveryPrice === null) {
            avgDeliveryPrice = parseFloat(state.data.avg_delivery_price || 0);
        }

        if (amountUnit === null) {
            amountUnit = parseFloat(state.data.amount_unit || 1);
        }

        if (price === null) {
            price = parseFloat(state.data.actual_price || 0);
        }

        let pricePerUnit = removeExtraPrice(price, taxval) / amountUnit;

        let profit = 0;

        if (avgDeliveryPrice > 0) {
            profit = (pricePerUnit / avgDeliveryPrice - 1) * 100;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                profit_perc: profit
            }
        }));
    }

    const setMargin = (avgDeliveryPrice = null, amountUnit = null, price = null) => {

        let tax = getTaxGroupById(state.data.tax_id);

        let taxval = parseFloat(tax?.value || 0);

        if (avgDeliveryPrice === null) {
            avgDeliveryPrice = parseFloat(state.data.avg_delivery_price || 0);
        }

        if (amountUnit === null) {
            amountUnit = parseFloat(state.data.amount_unit || 1);
        }

        if (price === null) {
            price = parseFloat(state.data.actual_price || 0);
        }

        let pricePerUnit = removeExtraPrice(price, taxval) / amountUnit;

        let perc = 0;

        if (pricePerUnit > 0) {
            perc = ((pricePerUnit - avgDeliveryPrice) / pricePerUnit) * 100;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                margin_perc: perc
            }
        }));
    }

    const setActualPrice = (avgDeliveryPriceWithVat = null, profit = null) => {
        if (avgDeliveryPriceWithVat === null) {
            avgDeliveryPriceWithVat = parseFloat(state.data.avg_delivery_price_with_vat || 0);
        }

        if (profit === null) {
            profit = parseFloat(state.data.profit_perc || 0);
        }

        let price = addExtraPrice(avgDeliveryPriceWithVat, profit);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                actual_price: price
            }
        }));
    }

    const handleCurrencyChange = e => {
        let id = e.target.value;

        let currency = currencies.data.find(c => Number(c.id) === Number(id));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency
            }
        }));
    }

    const handleActualPriceChange = e => {
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                actual_price: value,
            }
        }));

        setProfit(null, null, value);
        setMargin(null, null, value);
    }

    const handleProfitChange = e => {
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                profit_perc: value,
            }
        }));

        setActualPrice(null, value);
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: prev.data.files.concat(files)
            }
        }));
    }

    const handleDetach = (id, index) => {

        let file = state.data.files.filter((f, i) => {
            return Number(f.id) === Number(id);
        })[0];

        if (!file.was_recently_attached) {
            Api.post('articles/delete-file', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    removeFile(id);
                }
            });
        } else {
            removeFile(id);
        }

        if (validations?.files && validations.files[index]) {
            delete validations.files[index];
        }

        setValidations(validations);
    }

    const removeFile = id => {
        let files = state.data.files.filter((f, i) => {
            return Number(f.id) !== Number(id);
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: files
            }
        }));
    }

    const handleSortFiles = items => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: items
            }
        }));
    }

    const handleIsForSaleChange = e => {
        const name = e.target.name;

        // handleCheckboxChange(e);

        const checkForSale = (check) => {
            let storesdetails = cloneDeep(state.data.storesdetails);

            stores.map(store => {
                if (!storesdetails[store.id]) {
                    storesdetails[store.id] = {};
                };

                storesdetails[store.id].is_for_sale = check;
            });

            return storesdetails;
        }

        // ако е разерешена продажба да се маркира разрешена продажба и по магазини
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true,
                storesdetails: prev.data[name] ? checkForSale(false) : checkForSale(true),
            }
        }));
    }

    const handleStoreDetailChange = (storeId, name, value) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                storesdetails: {
                    ...prev.data.storesdetails,
                    [storeId]: {
                        ...prev.data.storesdetails[storeId],
                        [name]: prev.data.storesdetails[storeId] ? !prev.data.storesdetails[storeId][name] : true,
                    }
                }
            }
        }))
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const hasMainTabValidate = () => {
        let valid = false;

        let main = [
            'article_name',
            'langs',
            'amount_type_id',
            'amount_unit',
            'tax_id',
            'currency_id',
            'avg_delivery_price',
            'avg_delivery_price_with_vat',
            'actual_price',
            'profit_perc',
            'ref_num',
            'barcode'
        ];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'main') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const hasFilesTabValidate = () => {
        let valid = false;

        let main = ['files'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'files') {
                    valid = true
                }
            })
        }

        return valid;
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <form ref={formRef} onSubmit={handleSave}>
                {state.dataLoading
                    ?
                    <div style={{ padding: '150px 0' }}>
                        <Loader />
                    </div>
                    :
                    <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                        <Row>
                            <Col xl={2} sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link style={{ color: hasMainTabValidate() ? 'red' : '' }} eventKey="main">Основни</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="extra">Допълнителни</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={{ color: hasFilesTabValidate() ? 'red' : '' }} eventKey="files">Изображения</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xl={10} sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="main">

                                        {state.data.master_id &&
                                            <Alert>
                                                Този артикул е разновидност на артикул с ID {state.data.master_id} и въвеждането на някои от основните данни като име и описание не е задължително.
                                                Ако някои от данните не бъдат въведени, ще се визаулизират данните на главния артикул.
                                            </Alert>
                                        }

                                        <Tabs
                                            activeKey={state.tabId}
                                            onSelect={handleTabChange}
                                            className="mb-3"
                                        >
                                            {langs.data.map(lang =>
                                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''} />
                                            )}
                                        </Tabs>

                                        <FloatingLabel label="Име на артикул" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name="article_name"
                                                placeholder="Име на артикул"
                                                value={state.data.article_name || ''}
                                                onChange={handleInputChange}
                                                isInvalid={Boolean(validations && validations.article_name)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations && validations.article_name && (validations.article_name[0] || validations.article_name)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>

                                        {langs.data.map(lang =>
                                            <div key={lang.id} className="mb-3" style={{ display: state.tabId === lang.id ? 'block' : 'none' }}>
                                                <FloatingLabel label="Публично име" className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name={`langs[${lang.id}][name]`}
                                                        placeholder="Публично име"
                                                        value={state.data.langs[lang.id]?.name || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                                    </Form.Control.Feedback>
                                                    <HelpInfo placement="top">Ако не бъде въведено ще се използва основното име на артикула</HelpInfo>
                                                </FloatingLabel>

                                                <FloatingLabel className='hidden' label="Описание">
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`langs[${lang.id}][description]`}
                                                        placeholder="Описание"
                                                        value={state.data.langs[lang.id]?.description || ''}
                                                        onChange={handleInputChange}
                                                        style={{ height: '100px' }}
                                                        isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>

                                                <Col>
                                                    <Form.Label>Описание</Form.Label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            // plugins: [],
                                                            // toolbar: ['heading', '|', 'bold', 'italic', 'insertTable'],
                                                            // table: {
                                                            //     contentToolbar: ['tableRow', 'tableColumn', 'mergeTableCells', 'tableCellProperties']
                                                            // }
                                                        }}
                                                        data={state.data.langs[lang.id]?.description || ''}
                                                        onChange={(event, editor) => {
                                                            const name = `langs[${lang.id}][description]`
                                                            const value = editor.getData();
                                                            setState(name, value, 'data')
                                                        }}
                                                    />
                                                    <div className='validate'>
                                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                                    </div>
                                                </Col>

                                                <FloatingLabel className='hidden' label="Таблица">
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`langs[${lang.id}][table]`}
                                                        placeholder="Таблица"
                                                        value={state.data.langs[lang.id]?.table || ''}
                                                        onChange={handleInputChange}
                                                        style={{ height: '100px' }}
                                                        isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].table)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].table && (validations.langs[lang.id].table[0] || validations.langs[lang.id].table)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>

                                                <Col className="mt-2">
                                                    <Form.Label>Таблица</Form.Label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={state.data.langs[lang.id]?.table || ''}
                                                        onChange={(event, editor) => {
                                                            const name = `langs[${lang.id}][table]`
                                                            const value = editor.getData();
                                                            setState(name, value, 'data')
                                                        }}
                                                    />
                                                    <div className='validate'>
                                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].table && (validations.langs[lang.id].table[0] || validations.langs[lang.id].table)}
                                                    </div>
                                                </Col>

                                            </div>
                                        )}

                                        <Row>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Мерна единица">
                                                    <Form.Select
                                                        name="amount_type_id"
                                                        value={state.data.amount_type_id || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.amount_type_id)}
                                                    >
                                                        <option value=""></option>
                                                        {amountTypes.map(c =>
                                                            <option key={c.id} value={c.id}>{c?.translation?.name || c?.translation?.short_name}</option>
                                                        )}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.amount_type_id && (validations.amount_type_id[0] || validations.amount_type_id)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Единица продажба">
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        step="0.01"
                                                        name="amount_unit"
                                                        placeholder="Единица продажба"
                                                        value={state.data.amount_unit || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.amount_unit)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.amount_unit && (validations.amount_unit[0] || validations.amount_unit)}
                                                    </Form.Control.Feedback>
                                                    <HelpInfo placement="top">Количество, което се изтегля от склада при единичната продажба</HelpInfo>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="ДДС група">
                                                    <Form.Select
                                                        name="tax_id"
                                                        value={state.data.tax_id || ''}
                                                        onChange={handleTaxChange}
                                                        isInvalid={Boolean(validations && validations.tax_id)}
                                                    >
                                                        <option value="" disabled></option>
                                                        {taxGroups.map(c =>
                                                            <option key={c.id} value={c.id}>{c.name}</option>
                                                        )}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.tax_id && (validations.tax_id[0] || validations.tax_id)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Валута">
                                                    <Form.Select
                                                        name="currency_id"
                                                        value={state.data.currency_id || ''}
                                                        onChange={handleCurrencyChange}
                                                    >
                                                        {currencies.data.map(c =>
                                                            <option key={c.id} value={c.id}>{c.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Себестойност без ДДС">
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        step="0.01"
                                                        name="avg_delivery_price"
                                                        placeholder="Себестойност без ДДС"
                                                        value={state.data.avg_delivery_price || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    <HelpInfo placement="top">Средно претеглена себестойност без ДДС за единица основно количество (кг, л, бр)</HelpInfo>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Себестойност с ДДС">
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        step="0.01"
                                                        name="avg_delivery_price_with_vat"
                                                        placeholder="Себестойност с ДДС"
                                                        value={state.data.avg_delivery_price_with_vat || ''}
                                                        readOnly
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Продажна цена">
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        step="0.01"
                                                        name="actual_price"
                                                        placeholder="Продажна цена"
                                                        value={state.data.actual_price || ''}
                                                        onChange={handleActualPriceChange}
                                                    />
                                                    <HelpInfo placement="top">Основна продажна цена</HelpInfo>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Надценка (%)">
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        step="0.01"
                                                        name="profit_perc"
                                                        placeholder="Надценка (%)"
                                                        value={state.data.profit_perc || ''}
                                                        onChange={handleProfitChange}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>

                                        <FloatingLabel label="Референтен номер" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name="ref_num"
                                                placeholder="Референтен номер"
                                                value={state.data.ref_num || ''}
                                                onChange={handleInputChange}
                                            />
                                        </FloatingLabel>

                                        <FloatingLabel label="Общ номер" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name="art_num"
                                                placeholder="Общ номер"
                                                value={state.data.art_num || ''}
                                                onChange={handleInputChange}
                                            />
                                            <HelpInfo>Използва се за свързване на сходните артикули</HelpInfo>
                                        </FloatingLabel>

                                        <FloatingLabel label="Баркод" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name="barcode"
                                                placeholder="Баркод"
                                                value={state.data.barcode || ''}
                                                onChange={handleInputChange}
                                            />
                                        </FloatingLabel>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="extra">
                                        <Card className="mb-3">
                                            <Card.Header>
                                                Продажба
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs="auto">
                                                        <Form.Check
                                                            type="switch"
                                                            name="is_for_sale"
                                                            label="Разреши продажба"
                                                            value={1}
                                                            checked={Number(state.data?.is_for_sale) === 1}
                                                            onChange={handleIsForSaleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                {stores.map(store =>
                                                    <Row key={store.id} style={{ marginLeft: '25px' }}>
                                                        <Col xs="auto">
                                                            <Form.Check
                                                                type="switch"
                                                                name={`storesdetails[${store.id}][is_for_sale]`}
                                                                label={`Разреши продажба в ${store?.translation?.name || store.name}`}
                                                                value={1}
                                                                checked={state.data.storesdetails && state.data.storesdetails[store.id] && Number(state.data.storesdetails[store.id].is_for_sale) === 1}
                                                                disabled={Number(state.data.is_for_sale) !== 1}
                                                                onChange={e => handleStoreDetailChange(store.id, 'is_for_sale', 1)}
                                                            />
                                                        </Col>
                                                    </Row>

                                                )}
                                                <Form.Check
                                                    type="switch"
                                                    name="is_public"
                                                    label="Публичен артикул"
                                                    value={1}
                                                    checked={Number(state.data?.is_public) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    name="is_service"
                                                    label="Услуга"
                                                    checked={Number(state.data?.is_service) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Card.Body>
                                        </Card>
                                        <Card className="mb-3">
                                            <Card.Header>
                                                Складова наличност
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="Мин. наличност">
                                                            <Form.Control
                                                                type="number"
                                                                step="0.001"
                                                                min="0"
                                                                name="min_quantity"
                                                                placeholder="Мин. наличност"
                                                                value={state.data.min_quantity || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.min_quantity)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.min_quantity && (validations.min_quantity[0] || validations.min_quantity)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="Макс. наличност">
                                                            <Form.Control
                                                                type="number"
                                                                step="0.001"
                                                                min="0"
                                                                name="max_quantity"
                                                                placeholder="Макс. наличност"
                                                                value={state.data.max_quantity || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.max_quantity)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.max_quantity && (validations.max_quantity[0] || validations.max_quantity)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                {depots.length > 0 &&
                                                    <Dropdown >
                                                        <Dropdown.Toggle size="sm">
                                                            По складове
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu align="start" style={{ padding: '15px', width: '100%', maxHeight: '250px', overflow: 'auto' }}>
                                                            {depots.map((group, i) =>
                                                                <React.Fragment key={i}>
                                                                    <div style={{ padding: '5px', textAlign: 'center', background: '#eee', marginBottom: '10px' }}>
                                                                        {group.store?.name}
                                                                    </div>
                                                                    {group.depots.map(depot =>
                                                                        <Row className="mb-3" key={depot.id}>
                                                                            <Col>
                                                                                <FloatingLabel label="Склад">
                                                                                    <Form.Control
                                                                                        size="sm"
                                                                                        type="text"
                                                                                        placeholder="Склад"
                                                                                        value={depot?.translation?.name || depot?.name || ''}
                                                                                        disabled
                                                                                    />
                                                                                </FloatingLabel>
                                                                            </Col>
                                                                            <Col>
                                                                                <FloatingLabel label="Мин. наличност">
                                                                                    <Form.Control
                                                                                        size="sm"
                                                                                        type="number"
                                                                                        step="0.001"
                                                                                        min="0"
                                                                                        name={`depotsdetails[${depot.id}][min_quantity]`}
                                                                                        placeholder="Мин. наличност"
                                                                                        value={state.data.depotsdetails && state.data.depotsdetails[depot.id] ? state.data.depotsdetails[depot.id].min_quantity : ''}
                                                                                        onChange={handleInputChange}
                                                                                        isInvalid={Boolean(validations && validations.depotsdetails && validations.depotsdetails[depot.id] && validations.depotsdetails[depot.id].min_quantity)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {validations && validations.depotsdetails && validations.depotsdetails[depot.id] && validations.depotsdetails[depot.id].min_quantity && (validations.depotsdetails[depot.id].min_quantity[0] || validations.depotsdetails[depot.id].min_quantity)}
                                                                                    </Form.Control.Feedback>
                                                                                </FloatingLabel>
                                                                            </Col>
                                                                            <Col>
                                                                                <FloatingLabel label="Макс. наличност">
                                                                                    <Form.Control
                                                                                        size="sm"
                                                                                        type="number"
                                                                                        step="0.001"
                                                                                        min="0"
                                                                                        name={`depotsdetails[${depot.id}][max_quantity]`}
                                                                                        placeholder="Макс. наличност"
                                                                                        value={state.data.depotsdetails && state.data.depotsdetails[depot.id] ? state.data.depotsdetails[depot.id].max_quantity : ''}
                                                                                        onChange={handleInputChange}
                                                                                        isInvalid={Boolean(validations && validations.depotsdetails && validations.depotsdetails[depot.id] && validations.depotsdetails[depot.id].max_quantity)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {validations && validations.depotsdetails && validations.depotsdetails[depot.id] && validations.depotsdetails[depot.id].max_quantity && (validations.depotsdetails[depot.id].max_quantity[0] || validations.depotsdetails[depot.id].max_quantity)}
                                                                                    </Form.Control.Feedback>
                                                                                </FloatingLabel>
                                                                            </Col>
                                                                        </Row>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                }
                                            </Card.Body>
                                        </Card>
                                        <Row>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Код" className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="code"
                                                        placeholder="Код"
                                                        value={state.data.code || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    <HelpInfo placement="top">Код за бързо набиране от клавиатура</HelpInfo>
                                                </FloatingLabel>
                                            </Col>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Интрастат" className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="intrastat"
                                                        placeholder="Интрастат"
                                                        value={state.data.intrastat || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="files">
                                        {state.data.master_id &&
                                            <Alert>
                                                Този артикул е разновидност на артикул с ID {state.data.master_id} и прикачването на изображения не е задължително.
                                                Ако няма прикачени изображения, ще се визаулизират тези на главния артикул.
                                            </Alert>
                                        }

                                        {state.data.files.length === 0
                                            ?
                                            <Alert variant="primary">
                                                Няма прикачени изображения
                                            </Alert>
                                            :
                                            <>
                                                <Alert variant="primary">
                                                    Можете да размествате подредбата на изображенията като придърпвате дадено изображение измежду останалите.
                                                    <br />
                                                    Първото изображение е главно и ще бъде показвано по подразбиране.
                                                </Alert>
                                                <div className="files mb-3 sortable-list">
                                                    {state.data.files.map((file, index) =>
                                                        <SortItem
                                                            key={index}
                                                            items={state.data.files}
                                                            onSortItems={handleSortFiles}
                                                            sortId={index}
                                                            className="file-container"
                                                        >
                                                            <div className="file">

                                                                {file.type.includes('video')
                                                                    ?
                                                                    <video src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                                    :
                                                                    <img src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                                }

                                                                <div className="buttons">
                                                                    <Button variant="danger" size="sm" onClick={e => handleDetach(file.id, index)}>
                                                                        <TrashFill />
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <div className="validate">
                                                                {validations?.files && validations.files[index] && (validations.files[index][0] || validations.files[index])}
                                                            </div>

                                                            <input name={`files_position[${index}]`} value={file.was_recently_attached ? 0 : file.id} hidden readOnly />
                                                        </SortItem>

                                                    )}
                                                </div>
                                            </>
                                        }

                                        <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                            Прикачете изображения
                                        </Button>

                                        <input ref={inputFileRef} type="file" accept=".png,.jpeg,.jpg,.gif,.webp,.mp4" onChange={handleAttach} hidden multiple />

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                }
            </form>
        </>
    )
}

export default forwardRef(Index);