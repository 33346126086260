import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button, ButtonGroup, Col, Nav, Row, Table } from 'react-bootstrap';

import Options from './partials/Options';

import Filter from './partials/Filter';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { useAuthDataContext } from 'providers/Auth';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';
import Refs from 'Refs';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import { useAppContext } from 'providers/App';
import axios from 'axios';
import AddOrEdit from './partials/AddOrEdit';

let timeout;

function Pages(props, ref) {
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();
    const app = useAppContext();

    const addOrEditModalRef = useRef(null);
    const tableRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
        onDataLoaded: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                bad_title: query.get('bad_title') || '',
                bad_meta_description: query.get('bad_meta_description') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'admin/seo/pages';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {

            if (typeof state.onDataLoaded === 'function') {
                state.onDataLoaded(res.data);
            }

            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                onDataLoaded: null,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleShow = id => {
        let item = {
            id: id,
            type: 'page'
        };

        handleEdit(item);

    }

    // update
    const handleEdit = (data) => {
        let modal = addOrEditModalRef.current;

        if (!modal) {
            return;
        }

        modal.edit(data.id, data.type);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const showNextItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let nextRow = thisRow.nextSibling;

        if (nextRow) {
            showItem(
                nextRow.getAttribute('data-id')
            );
        } else {
            showNextPage();
        }
    }

    const showPrevItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let prevRow = thisRow.previousSibling;

        if (prevRow) {
            showItem(
                prevRow.getAttribute('data-id')
            );
        } else {
            showPrevPage();
        }
    }

    const showNextPage = () => {
        if (state.pages > state.filter.page) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) + 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[0].page_id);
                    }, 0)
                },
                setFilter: new Date().getTime(),
            }));
        }
    }

    const showPrevPage = () => {
        if (state.filter.page > 1) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) - 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[data.items.length - 1].page_id);
                    }, 0)
                },
                setFilter: new Date().getTime(),
            }));
        }
    }

    const showItem = id => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        let row = table.querySelector('tr[data-id="' + id + '"]');

        if (row) {
            row.click();

            handleShow(id);
        }
    }

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
                showNextItem={id => showNextItem(id)}
                showPrevItem={id => showPrevItem(id)}
            />

            <Row>
                <Col sm={2}>
                    {props.tabs}
                </Col>
                <Col sm={10}>
                    <Filter
                        filter={state.filter}
                        handleSearch={handleSearch}
                    />

                    {state.loading && !state.sorting
                        ?
                        <Loader />
                        :
                        state.data.length === 0 ?
                            <NoDataFound />
                            :
                            <>
                                <div className="pagination-with-options mb-3">
                                    <Pagination
                                        page={state.filter.page}
                                        pages={state.pages}
                                        handlePage={handlePage}
                                    />

                                    {/* <Nav
                                variant="pills"
                            // activeKey={state.tabId}
                            // onSelect={(k) => setKey(k)}
                            // unmountOnExit
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="test">
                                        Категории
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="123">
                                        Артикули
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav> */}

                                    <TableOptions
                                        url="admin/seo/pages"
                                        filter={state.filter}
                                        columns={state.columns}
                                        tableKey={state.tableKey}
                                        refresh={refreshTable}
                                    />
                                </div>

                                <Table className={Object.entries(state.headings).length > 14 ? 'xxl' : 'big'} responsive striped hover ref={tableRef}>
                                    <TableHeader
                                        tableRef={tableRef}
                                        activeSortKey={state.filter.sort}
                                        activeSortDir={state.filter.order}
                                        onSort={(col, dir) => handleSort(col, dir)}
                                    >
                                        {Object.entries(state.headings).map((heading, i) =>
                                            <DynamicTableCol
                                                key={i}
                                                type={state.types[heading[0]]}
                                                name={heading[1]}
                                                title={state.columns.description[heading[0]]}
                                                sortKey={heading[0]}
                                                sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                            />
                                        )}
                                        <th className="options">
                                            Опции
                                        </th>
                                    </TableHeader>
                                    <tbody>
                                        {state.data.map(c =>
                                            <tr
                                                key={'c-' + c.id}
                                                className={c.deleted ? 'deleted' : ''}
                                                data-id={c.page_id}
                                            >
                                                {Object.entries(state.headings).map((heading, i) =>
                                                    <DynamicTableBodyCol
                                                        key={heading[0]}
                                                        type={state.types[heading[0]]}
                                                        name={c[heading[0]]}
                                                        data={c}
                                                    />
                                                )}
                                                <td className="options">
                                                    <MenuOptions>
                                                        <Options
                                                            data={c}
                                                            handleEdit={handleEdit}
                                                        />
                                                    </MenuOptions>
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </Table>

                                <Pagination
                                    className="mt-3"
                                    page={state.filter.page}
                                    pages={state.pages}
                                    total={state.total}
                                    handlePage={handlePage}
                                />
                            </>
                    }
                </Col>
            </Row>
        </>

    )

}

export default forwardRef(Pages);