import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, FloatingLabel, Form, Col, Row, Tabs, Tab } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import { render } from 'react-dom';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [views, setViews] = useState([]);
    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('admin/pages/views')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setViews(res.data);
                } else {
                    const keys = Object.keys(res.data);
                    const values = Object.values(res.data);
                    let arr = []
                    keys.map((t, i) => arr.push({
                        value: values[i],
                        name: t,
                    }))
                    setViews(arr);
                }
            });
    }, [])

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('admin/pages/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'admin/pages/update' : 'admin/pages/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const renderOptions = (data) => {

        if (typeof data.value === 'object') {
            let values = Object.values(data.value)
            let keys = Object.keys(data.value)

            return values.map((t, index) =>
                <option key={index} dangerouslySetInnerHTML={{ __html: '&nbsp&nbsp&nbsp&nbsp' + keys[index] }} value={t}></option>
            )
        }
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Страница
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col className="mb-3">
                            <FloatingLabel label="Път">
                                <Form.Control
                                    type="text"
                                    placeholder="Име*"
                                    name="route"
                                    value={state.data.route || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.route)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.route && (validations.route[0] || validations.route)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col className="mb-3">
                            <FloatingLabel label="Темплейт*">
                                <Form.Select
                                    name="layout"
                                    value={state.data.layout || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.layout)}
                                >
                                    {views.map(g =>
                                        typeof g.value === 'object' ?
                                            <>
                                                <option disabled>{g.name}</option>
                                                {renderOptions(g)}
                                            </>
                                            : ''
                                    )}


                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.layout && (validations.layout[0] || validations.layout)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>

                        <Tabs
                            defaultActiveKey={langs.data[0].id}
                            className="mb-3"
                            transition={false}
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                    <Col className="mb-3">
                                        <FloatingLabel label="Заглавие">
                                            <Form.Control
                                                type="text"
                                                name={`langs[${lang.locale}][title]`}
                                                placeholder="Заглавие"
                                                value={state.data.langs[lang.locale]?.title || ''}
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].title)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].title && (validations.langs[lang.locale].title[0] || validations.langs[lang.locale].title)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    {/* <Row className="mb-3">
                                        <Col >
                                            <FloatingLabel label="Описание (meta)" >
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.locale}][description]`}
                                                    placeholder="Заглавие"
                                                    value={state.data.langs[lang.locale]?.description || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].description)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].description && (validations.langs[lang.locale].description[0] || validations.langs[lang.locale].description)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel label="Ключови думи (meta)">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.locale}][keywords]`}
                                                    placeholder="Заглавие"
                                                    value={state.data.langs[lang.locale]?.keywords || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].keywords)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].keywords && (validations.langs[lang.locale].keywords[0] || validations.langs[lang.locale].keywords)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row> */}

                                    {/* <Row className="mb-3">
                                        <Col>
                                            <FloatingLabel label="og:type">
                                                <Form.Select
                                                    name={`langs[${lang.locale}][og_type]`}
                                                    value={state.data.langs[lang.locale]?.og_type || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].og_type)}
                                                >
                                                    <option value="article">Article</option>
                                                    <option value="website">Website</option>

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].og_type && (validations.langs[lang.locale].og_type[0] || validations.langs[lang.locale].og_type)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>

                                        <Col>
                                            <FloatingLabel label="og:title">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.locale}][og_title]`}
                                                    placeholder="Заглавие"
                                                    value={state.data.langs[lang.locale]?.og_title || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].og_title)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].og_title && (validations.langs[lang.locale].og_title[0] || validations.langs[lang.locale].og_title)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row> */}

                                    {/* <Col>
                                        <FloatingLabel label="og:description">
                                            <Form.Control
                                                type="text"
                                                name={`langs[${lang.locale}][og_description]`}
                                                placeholder="Заглавие"
                                                value={state.data.langs[lang.locale]?.og_description || ''}
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].og_description)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.langs && validations.langs[lang.locale] && validations.langs[lang.locale].og_description && (validations.langs[lang.locale].og_description[0] || validations.langs[lang.locale].og_description)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col> */}

                                </Tab>
                            )}
                        </Tabs>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
