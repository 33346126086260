import { cloneElement, createElement, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Layout from 'components/layout/Layout';
import Categories from './Categories';
import Articles from './Articles';
import Pages from './Pages';

import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';

let timeout;

function Index() {

    const tabs = [
        {
            id: 'categories',
            name: 'Категории',
            component: Categories
        },
        {
            id: 'articles',
            name: 'Артикули',
            component: Articles
        },
        {
            id: 'pages',
            name: 'Страници',
            component: Pages
        },
    ];

    const ref = useRef(null);

    const [state, setState] = useState({
        tabId: 'categories',
    });

    const [contentRef, setContentRef] = useState(null)

    useEffect(() => {

    }, []);

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const getActiveTab = () => {
        return tabs.find(tab => tab.id === state.tabId);
    }

    const renderFilter = () => {
        return ref.current?.renderFilter();
    };

    const getTabs = () => {
        return (
            <Nav
                variant="pills"
                activeKey={state.tabId}
                onSelect={(k) => setKey(k)}
                className="flex-column"
                // unmountOnExit
                style={{
                    position: 'sticky',
                    top: '10px'
                }}
            >
                {tabs.map(tab =>
                    <Nav.Item key={tab.id}>
                        <Nav.Link eventKey={tab.id} title={tab.name}>
                            {tab.name}
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
        )
    }



    return (
        <>
            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            SEO съдържание
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        {getActiveTab() &&
                            createElement(getActiveTab().component, {
                                ref: ref,
                                tabs: getTabs(),
                            })
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;